html {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.cell {
  background-color: black;
  height: 50px;
  width: 50px;
  border: 1px solid white;
}

.chrome-picker  {
  width: 100vw!important;
}

.hue-horizontal {
  height: 6vw!important;

}

.chrome-picker > div:last-child {
  height: 3vw!important;
  -webkit-transform: translateY(-20%);
          transform: translateY(-20%);
}

.chrome-picker > div:first-child {
  display: none!important;
}

.chrome-picker > div:last-child > div:last-child {
  display: none!important;
}

.btn-save {
  background-image: -webkit-linear-gradient(left, #0198FF 0%, #724CFF 100%);
  background-image: linear-gradient(-270deg, #0198FF 0%, #724CFF 100%);
  font-family: Avenir-Heavy;
  font-size: 14px;
  color: #FFFFFF;
  text-align: left;
  z-index: 1000;
  border: none;
  padding: 8px 26px;
  border: none;
    text-transform: uppercase;
  letter-spacing: 1.9px;
  text-align: center;
}

.color-picker-wrpaper {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: -webkit-transform .3s ease-out;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  position: fixed;
  bottom: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.color-picker-wrpaper.active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.color-picker-wrpaper.active ~ div {
  margin-bottom: 6.5vh;
  padding-bottom: 6.5vh;
}
