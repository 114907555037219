.cell {
  background-color: black;
  height: 50px;
  width: 50px;
  border: 1px solid white;
}

.chrome-picker  {
  width: 100vw!important;
}

.hue-horizontal {
  height: 6vw!important;

}

.chrome-picker > div:last-child {
  height: 3vw!important;
  transform: translateY(-20%);
}

.chrome-picker > div:first-child {
  display: none!important;
}

.chrome-picker > div:last-child > div:last-child {
  display: none!important;
}

.btn-save {
  background-image: linear-gradient(-270deg, #0198FF 0%, #724CFF 100%);
  font-family: Avenir-Heavy;
  font-size: 14px;
  color: #FFFFFF;
  text-align: left;
  z-index: 1000;
  border: none;
  padding: 8px 26px;
  border: none;
    text-transform: uppercase;
  letter-spacing: 1.9px;
  text-align: center;
}

.color-picker-wrpaper {
  transform: translateY(100%);
  transition: transform .3s ease-out;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

.color-picker-wrpaper.active {
  transform: translateY(0);
}

.color-picker-wrpaper.active ~ div {
  margin-bottom: 6.5vh;
  padding-bottom: 6.5vh;
}